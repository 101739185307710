import React from 'react';
import { Route } from 'react-router-dom';
import Layout from '../../../Layout/index';
import Default from '../../../Dashboards/Default';
import UsersStaffs from '../../../Users/UsersStaffs';

import ShortCodes from '../../../Users/shortcodes';
import SendBlast from '../../../Users/SendBlast';
import { BlastedMessages } from '../../../Users/getblasted_messages';
import AddContact from '../../../Users/AddContact';
import SouthMigarango from '../../../Users/SouthMigarango';
import Voters from '../../../Users/Voters';



export default () => (
  <div>
    <Layout />
    <div className="container__wrap">
      <Route path="/staffs" component={UsersStaffs} />
      <Route path="/shortcodes" component={ShortCodes} />
      <Route path="/send_blast" component={SendBlast} />
      <Route path="/blast_logs" component={BlastedMessages} />


      <Route path="/add_contact" component={AddContact} />

      <Route path="/south_migarango_form" component={SouthMigarango} />

      <Route path="/voters" component={Voters} />



      


    </div>
  </div>
);
