import axios from 'axios';
import React, { Fragment, useEffect, useState } from 'react';
import { CardBody, Card, Button, Form } from 'reactstrap';
import { baseURL_PORTAL, CONFIG, errorToast, successToast, ToastTable } from '../../configs/exports';
import ReactDatatable from '@ashvin27/react-datatable';
import moment from 'moment';
import { MDBCloseIcon } from "mdbreact";
import Modal from 'react-modal';
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import Select from "react-select";

const AddContact = () => {

    // hooks definations variables srtores
    //const [value, setValue] = useState()
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [name, setName] = useState("")
    const [msisdn, setMsisdn] = useState("")
    const [open_add, IsOpenAdd] = useState(false);  // define open edit modal

    const [data, setData] = useState([]);  // array of objects for data table  
    const [isLoading, setLoad] = useState(false); // loading state
    const [randomstrng, setRandomString] = useState(''); // random string for the api



    const [title_name, setTitleName] = useState('mca')


    const [county, setCounty] = useState([]);
    const [county_name, setCountyName] = useState('')

    const [county_id, setCountyId] = useState(38)


    const [consituency, setConstituency] = useState([]);


    const [consituency_name, setConstituencyName] = useState()
    const [consituency_id, setConstituencyID] = useState(0)

    const [ward, setWard] = useState([]);
    const [ward_name, setWardName] = useState('')

    const [ward_id, setWardID] = useState(1)

    const [polling_station, setPollingStation] = useState([]);
    const [p_name, setPName] = useState('')

    // function being called once a component has been loaded
    useEffect(() => {
        setLoad(true);
        axios.get(`${baseURL_PORTAL}counties`, CONFIG).then(res => {
            // console.log("Users", res.data.Users[1])
            if (res.data.data.length > 0) {
                setCounty(res.data.data);
            }
            setLoad(false);

        }).catch(err => {
            console.log(err) //log error
            setLoad(false);
            // if error on the api
        })



    }, [randomstrng])


    //  close add modal
    const closeModalAdd = () => {
        // IsOpenAdd(false)
    }

    const handleChangeNanme = (e) => {
        setName(e.target.value)
    }
    // agent specific option


    const onSubmitHandleAdd = (e) => {
        e.preventDefault();
        setLoad(true);


        let formatData = {
            name: name,
            msisdn: msisdn,
            county_name: county_name,
            constituency_name: consituency_name,
            ward_name: ward_name,
            consituency_id: consituency_id,
            county_id: county_id,
            ward_id: ward_id
        }

        console.log("FORMDATA", formatData)


        axios.post(`${baseURL_PORTAL}contact`, formatData, CONFIG).then(res => {
            setLoad(false);
            if (res.data.success) {
                successToast("Success");
                // reload  after three sec0nd of success
                setTimeout(() => {
                    setRandomString(require("randomstring").generate({
                        length: 1,
                        charset: 'alphanumeric',
                        capitalization: 'lowercase'
                    }))
                    window.location.reload()
                    IsOpenAdd(false);
                }, 1000)
            } else {
                errorToast("Phone Exist");
            }

        }).catch(err => {
            errorToast(err.response.data.message);
        })



    }

    //return function of UI
    return (
        <div>

            {ToastTable()}

            <Modal
                isOpen={true}
                onRequestClose={e => {
                    closeModalAdd(e);
                }}

                contentLabel="My dialog"
                className="mymodal"
                onAfterOpen={() => {
                    document.body.style.overflow = 'hidden';
                }}
                onAfterClose={() => {
                    document.body.removeAttribute('style');
                }}
                overlayClassName="myoverlay"
                closeTimeoutMS={500}
            >
                <MDBCloseIcon onClick={closeModalAdd} />
                <h4><b>Add Phone</b></h4>
                <br></br>
                <>
                    <Form className="form login-form" onSubmit={onSubmitHandleAdd} >

                        <div className="col-md-10 offset-1"><br />
                            <div className="form-group">
                                <div className="col-md-12">
                                    <label className="form-label">Name</label>
                                </div>
                                <div className="col-md-12">
                                    <input id="input" type="text
                                                        "
                                        className="form-control"
                                        name="name" placeholder='Name'
                                        value={name} onChange={handleChangeNanme} />
                                </div>
                            </div>
                        </div>
                        {title_name === 'governor' || title_name === 'mp' || title_name === 'mca' ?
                            <>
                                <div className="col-md-10 offset-1">
                                    <div className="form-group">
                                        <div className="col-md-12">
                                            <label className="form-label">Counties</label>
                                        </div>
                                        <div className="col-md-12">
                                            <Select
                                                isClearable
                                                options={
                                                    (county && county.length > 0 || county.length === 0) &&
                                                    county.map((countyItem, i) => ({
                                                        label: countyItem.county_name,
                                                        value: countyItem.id
                                                    }))}
                                                placeholder="Select Counties"
                                                autosize={true}
                                                onChange={e => {

                                                    setCountyId(e.value)
                                                    setCountyName(e.label)
                                                    axios.get(`${baseURL_PORTAL}constituencies?id=${e.value}`, CONFIG).then(res => {
                                                        if (res.data.data.length > 0) {
                                                            setConstituency(res.data.data);
                                                        }
                                                    }).catch(err => {
                                                        console.log(err) //log error
                                                        setLoad(false);
                                                        // if error on the api
                                                    })
                                                }}
                                                className="selected"
                                                menuPortalTarget={document.body}
                                                name="namffe"
                                            />
                                        </div>

                                    </div>
                                </div>

                                {title_name === 'mp' || title_name === 'mca' ?
                                    <>
                                        <div className="col-md-10 offset-1">
                                            <div className="form-group">
                                                <div className="col-md-12">
                                                    <label className="form-label">Constituencies</label>
                                                </div>
                                                <div className="col-md-12">
                                                    <Select
                                                        isClearable
                                                        options={
                                                            (consituency && consituency.length > 0 || consituency.length === 0) &&
                                                            consituency.map((countyItem, i) => ({
                                                                label: countyItem.name,
                                                                value: countyItem.cosnt_code
                                                            }))}
                                                        placeholder="Select Consituency"
                                                        autosize={true}
                                                        onChange={e => {
                                                            setConstituencyID(e.value)

                                                            setConstituencyName(e.label)
                                                            axios.get(`${baseURL_PORTAL}wards?id=${e.value}`, CONFIG).then(res => {
                                                                if (res.data.data.length > 0) {
                                                                    setWard(res.data.data);
                                                                }
                                                            }).catch(err => {
                                                                console.log(err) //log error
                                                                setLoad(false);
                                                                // if error on the api
                                                            })


                                                        }}
                                                        className="selected"
                                                        menuPortalTarget={document.body}
                                                        name="namffe"
                                                    />
                                                </div>

                                            </div>
                                        </div>

                                        {title_name === 'mca' ?
                                            <div className="col-md-10 offset-1">
                                                <div className="form-group">
                                                    <div className="col-md-12">
                                                        <label className="form-label">Ward</label>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <Select
                                                            isClearable
                                                            options={
                                                                (ward && ward.length > 0 || ward.length === 0) &&
                                                                ward.map((countyItem, i) => ({
                                                                    label: countyItem.name,
                                                                    value: countyItem.caw_code
                                                                }))}
                                                            placeholder="Select Wards"
                                                            autosize={true}
                                                            onChange={e => {
                                                                setWardID(e.value)
                                                                setWardName(e.label)
                                                                axios.get(`${baseURL_PORTAL}polling?id=${e.value}`, CONFIG).then(res => {
                                                                    if (res.data.data.length > 0) {
                                                                        setPollingStation(res.data.data);
                                                                    }
                                                                }).catch(err => {
                                                                    console.log(err) //log error
                                                                    setLoad(false);
                                                                    // if error on the api
                                                                })
                                                            }
                                                            }
                                                            className="selected"
                                                            menuPortalTarget={document.body}
                                                            name="namffe"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            : null}
                                    </>
                                    : null}
                            </>
                            : null}




                        <div className="col-md-10 offset-1">
                            <div className="form-group">
                                <div className="col-md-12">
                                    <label className="form-label">Phone Number</label>
                                </div>
                                <div className="col-md-12">
                                    <PhoneInput
                                        className="form-group"
                                        defaultCountry="KE"
                                        placeholder="Enter phone number"
                                        value={msisdn}
                                        id="input"
                                        onChange={value => setMsisdn(value)}
                                    />
                                </div>
                            </div>
                            <br />
                            <br />

                        </div>


                        <div className="account__btns col-8 offset-2" >
                            <Button className="account__btn" type='submit' color="success"> {
                                isLoading ? "Please wait..." : "Submit"
                            } </Button>
                        </div>
                    </Form>
                </>
            </Modal>

            <Card>
                <CardBody>

                </CardBody>

            </Card>
        </div >
    )
}

export default AddContact;