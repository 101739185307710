import React, { useState, Fragment, useEffect } from 'react';
import ReactDatatable from '@ashvin27/react-datatable';
import axios from 'axios';
import "../../_assets/css/file.css"
import { baseURL, successToast, errorToast, ToastTable, CONFIG, formatCurrency, baseURL_PORTAL, ROLE, USER_ID } from '../../configs/exports';
import RefreshIcon from 'mdi-react/RefreshCircleIcon';
import * as moment from 'moment';
import { MDBCloseIcon } from "mdbreact";
import Modal from 'react-modal';
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import Select from "react-select";
import { CardBody, Card, Button, Table, Form } from 'reactstrap';

// import FarmerDetails from './FarmerDetails';


//farmer hooks

const Voters = () => {


    const [farmer, setFarmer] = useState([])
    const [farmerProps, setFarmerProps] = useState([])
    const [isLoading, setLoad] = useState(true)
    const [datatotal, setDataTotal] = useState()
    const [queryString, setQueryString] = useState("")
    const [farmerDetails, setFarmerDetails] = useState(false)



    const [sponser_id, setSponserID] = useState("")
    const [password, setPassword] = useState("")

    const [id_number, setIDNumber] = useState("")

    const [name, setName] = useState("")
    const [msisdn, setMsisdn] = useState("")
    const [open_add, IsOpenAdd] = useState(false);  // define open edit modal


    const [open_edit, IsOpenEdit] = useState(false);  // define open edit modal

    const [data, setData] = useState([]);  // array of objects for data table  
    const [randomstrng, setRandomString] = useState(''); // random string for the api






    const [mstage, setStage] = useState(0)


    const [title, setTitle] = useState([
        {
            label: "Governor",
            value: "governor",
        },
        {
            label: "MP",
            value: "mp",
        },
        {
            label: "MCA",
            value: "mca",
        }
    ]);  // array of objects for data table  

    const [title_name, setTitleName] = useState('admin')


    const [county, setCounty] = useState([]);
    const [county_name, setCountyName] = useState('')

    const [consituency, setConstituency] = useState([]);
    const [consituency_name, setConstituencyName] = useState('')

    const [ward, setWard] = useState([]);
    const [ward_name, setWardName] = useState('')


    const [polling_station, setPollingStation] = useState([]);
    const [p_name, setPName] = useState('')

    const [user_id, setUserId] = useState('')


    const [category, setCategory] = useState([]);
    const [category_name, setCategoryName] = useState('')


    const [voter_type, setVoterType] = useState([]);
    const [voter_type_name, setVoterTypeName] = useState('')







    useEffect(() => {
        // setLoad(true)

        let url = baseURL + `voter?ward_id=${ward_name}&&polling_station_id=${p_name}&&category_id=${category_name}&&voter_type_id=${voter_type_name}&&sponser_id=${sponser_id}&&stage=${mstage}&` + queryString;
        // alert(url)
        axios.all([
            axios.get(url, CONFIG)
        ]).then(axios.spread((farmerResponse) => {
            let data = [];
            setDataTotal(farmerResponse.data.total)

            if (farmerResponse.data.data.length > 0) {
                // setDataTotal(farmerResponse.data.data.length)
                setLoad(false)

                let params = new URLSearchParams(queryString)
                let id = parseInt(params.get("page_number"))


                let p = farmerResponse.data.total + 1;
                if (id > 1) {
                    p = (parseInt(farmerResponse.data.total) - ((id - 1) * parseInt(params.get("page_size")))) + 1
                }
                /**
                * @description If list is
                */
                // let p = 0;
                // if (id > 1) {
                //      p = (((id-1) * parseInt(params.get("page_size"))))
                // }

                for (let i = 0; i < farmerResponse.data.data.length; i++) {
                    let f_amount = { f_amount: formatCurrency(parseFloat(farmerResponse.data.data[i].wallet_balance).toFixed(2)) };
                    p--;

                    let unrow = { row: p }

                    let date = { dates: (moment.utc(farmerResponse.data.data[i].date_created).format('DD/MM/YYYY')) };
                    data.push(Object.assign(date, unrow, f_amount, farmerResponse.data.data[i]));
                    setLoad(false)
                }

                // alert(farmerResponse.data.total)

                setFarmer(data)
            } else {
                setLoad(false)
                setFarmer(data)
            }
        }))





        axios.get(`${baseURL_PORTAL}wards_m?id=262`, CONFIG).then(res => {
            if (res.data.data.length > 0) {
                setWard(res.data.data);
            }
        }).catch(err => {
            console.log(err) //log error
            setLoad(false);
            // if error on the api
        })

        axios.get(`${baseURL_PORTAL}m_category`, CONFIG).then(res => {
            if (res.data.data.length > 0) {
                setCategory(res.data.data);
            }
        }).catch(err => {
            console.log(err) //log error
            setLoad(false);
            // if error on the api
        })

        axios.get(`${baseURL_PORTAL}m_voter`, CONFIG).then(res => {
            if (res.data.data.length > 0) {
                setVoterType(res.data.data);
            }
        }).catch(err => {
            console.log(err) //log error
            setLoad(false);
            // if error on the api
        })


    }, [queryString]);



    const config = {
        key_column: "tsc",
        length_menu: [100, 200, 500],
        show_filter: false,
        show_pagination: true,
        pagination: 'advance',
        page_size: 100,
        button: {
            csv: true
        },
        show_length_menu: true,
        language: {
            loading_text: "Please be patient while data loads...",
            filter: "Search here..",
            no_data_text: "There was no record found",
            pagination: {
                next: <span>&#9658;</span>,
                previous: <span>&#9668;</span>
            }
        }
    }
    const columns = [
        // {
        //     key: "row",
        //     TrOnlyClassName: 'cell',
        //     text: "#",
        //     className: "tsc",
        //     align: "left"
        // },
        {
            key: "name",
            TrOnlyClassName: 'cell',
            text: "Name",
            className: "tsc",
            align: "left"
        },

        {
            key: "msisdn",
            TrOnlyClassName: 'cell',
            text: "Phone#",
            className: "tsc",
            align: "left"
        },

        {
            key: "id_passport_no",
            TrOnlyClassName: 'cell',
            text: "ID/Passport No.",
            className: "tsc",
            align: "left"
        },

        {
            key: "constituency_name",
            TrOnlyClassName: 'cell',
            text: "Constituency",
            className: "tsc",
            align: "left"
        },
        {
            key: "ward_name",
            TrOnlyClassName: 'cell',
            text: "Ward",
            className: "tsc",
            align: "left"
        },
        {
            key: "polling_station_name",
            TrOnlyClassName: 'cell',
            text: "Polling Station",
            className: "tsc",
            align: "left",

        },

        {
            key: "category_type_name",
            TrOnlyClassName: 'cell',
            text: "Category",
            className: "tsc",
            align: "left",

        },



        {
            key: "voter_type_name",
            TrOnlyClassName: 'cell',
            text: "Voter Type",
            className: "tsc",
            align: "left",

        },



        {
            key: "sponsor_id",
            TrOnlyClassName: 'cell',
            text: "Sponser ID",
            className: "tsc",
            align: "left",

        },
        {
            key: "dates",
            TrOnlyClassName: 'cell',
            text: "Date",
            className: "tsc",
            align: "left"
        },
        ,
        {
            key: "action",
            text: "Options",
            TrOnlyClassName: 'cell',
            className: "cell",
            sortable: false,
            cell: record => {
                return (
                    <Fragment className="center" >
                        <button className="btn btn-primary btn-sm"
                            title="EditCategory"
                            style={
                                { marginRight: '10px' }}
                            onClick={() => { openEditModal(record) }} >

                            Edit
                        </button>

                        {/* <button className="btn btn-danger btn-sm"
                            title="Delete Category"
                            style={
                                { marginRight: '10px' }}

                            onClick={() => { if (window.confirm('Are you sure you want to delete this user?')) onSubmitDelete(record) }} >

                            Delete
                        </button> */}


                    </Fragment>
                );
            }
        }


    ];

    const openEditModal = (e) => {


        console.log("edit", e)

        IsOpenEdit(true);

        setUserId(e.id)
        setSponserID(e.sponsor_id)
        // setPassword(e.password)
        setIDNumber(e.id_passport_no)
        setName(e.name)
        setMsisdn(e.msisdn)
        setWardName(e.ward_id)
        setPName(e.polling_station_id)
        setCategoryName(e.category_type)
        setVoterTypeName(e.voter_type)

    }


    // row clickerd
    const rowClickedHandler = (data) => {
        setFarmerProps(data);
        setFarmerDetails(true);
    };

    const tableChangeHandler = data => {
        let queryString = Object.keys(data).map((key) => {
            if (key === "sort_order" && data[key]) {
                return encodeURIComponent("sort_order") + '=' + encodeURIComponent(data[key].order) + '&' + encodeURIComponent("sort_column") + '=' + encodeURIComponent(data[key].column)
            } else {
                return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }
        }).join('&');
        setQueryString(queryString)
    }



    const closeModalEdit = () => {
        IsOpenEdit(false)


    }





    // const closeModalEdit = () => {
    //     IsOpenEdit(false)


    // }



    //  close add modal
    const closeModalAdd = () => {
        IsOpenAdd(false)


    }

    const handleChangeUsername = (e) => {
        setSponserID(e.target.value)
        // setStage(6)

    }
    const handleChangePassword = (e) => {
        setPassword(e.target.value)
    }
    const handleChangeNanme = (e) => {
        setName(e.target.value)
    }
    // agent specific option

    // add branch action point
    // add user action point

    const onSubmitHandleAdd = (e) => {
        e.preventDefault();
        setLoad(true);
        closeModalAdd(e)
        setQueryString(require("randomstring").generate({
            length: 1,
            charset: 'alphanumeric',
            capitalization: 'lowercase'
        }))
    }


    const onSubmitHandleEdit = (e) => {
        e.preventDefault();
        setLoad(true);


        let formatData = {
            name: name,
            id: user_id,
            msisdn: msisdn,
            ward_id: ward_name,
            constituency_id: consituency_name,
            county_id: county_name,
            polling_station_id: p_name,
            category_id: category_name,
            voter_type_id: voter_type_name,
            sponsor_id: sponser_id,
            id_number: id_number
        }

        console.log("data", formatData)


        // // console.log("ID NUMBER", formatData)


        axios.post(`${baseURL_PORTAL}edit_voter`, formatData, CONFIG).then(res => {
            setLoad(false);
            if (res.data.status) {
                successToast("Edited successfully");


                setName("")
                setMsisdn("")
                setWardName("")
                setConstituencyName("")
                setCountyName("")
                setPName("")
                setCategoryName("")
                setVoterTypeName("")
                setSponserID("")
                setIDNumber("")

                setLoad(false);
               
                // window.location.reload()
                // reload  after three sec0nd of success
                setTimeout(() => {
                    setQueryString(require("randomstring").generate({
                        length: 1,
                        charset: 'alphanumeric',
                        capitalization: 'lowercase'
                    }))
                    closeModalEdit(e)
             
                    // window.location.reload()
                    // IsOpenAdd(false);
                }, 1000)
            }

        }).catch(err => {
            setLoad(false);
            errorToast(err.response.data.message);
        })



    }



    // return UI
    return (
        <div style={{ marginTop: "-20px" }} >
            < >

                {ToastTable()}

                <Modal
                    isOpen={open_edit}
                    onRequestClose={e => {
                        closeModalEdit(e);
                    }}

                    contentLabel="My dialog"
                    className="mymodal"
                    onAfterOpen={() => {
                        document.body.style.overflow = 'hidden';
                    }}
                    onAfterClose={() => {
                        document.body.removeAttribute('style');
                    }}
                    overlayClassName="myoverlay"
                    closeTimeoutMS={500}
                >
                    <br /><br /><br />
                    <MDBCloseIcon onClick={closeModalEdit} />
                    <h4><b>Edit Voter</b></h4>
                    <br></br>
                    <>
                        <Form className="form login-form" onSubmit={onSubmitHandleEdit} >

                            <div className="col-md-10 offset-1"><br />
                                <div className="form-group">
                                    <div className="col-md-12">
                                        <label className="form-label">Name</label>
                                    </div>
                                    <div className="col-md-12">
                                        <input id="input" type="text
                                                        "
                                            className="form-control"
                                            name="name" placeholder='Name'
                                            required
                                            value={name} onChange={handleChangeNanme} />
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-10 offset-1">
                                <div className="form-group">
                                    <div className="col-md-12">
                                        <label className="form-label">ID No</label>
                                    </div>
                                    <div className="col-md-12">
                                        <input id="input" type="text"
                                            className="form-control"
                                            name="id_number" placeholder='ID Number'
                                            value={id_number} onChange={e => setIDNumber(e.target.value)} />
                                    </div>
                                </div>
                            </div>



                            <div className="col-md-10 offset-1">
                                <div className="form-group">
                                    <div className="col-md-12">
                                        <label className="form-label">Phone Number</label>
                                    </div>
                                    <div className="col-md-12">
                                        <PhoneInput
                                            className="form-group"
                                            defaultCountry="KE"
                                            placeholder="Enter phone number"
                                            value={msisdn}
                                            id="input"
                                            onChange={value => setMsisdn(value)}
                                        />
                                    </div>
                                </div>
                            </div>



                            <div className="col-md-10 offset-1">
                                <div className="form-group">
                                    <div className="col-md-12">
                                        <label className="form-label">Ward</label>
                                    </div>
                                    <div className="col-md-12">
                                        <Select
                                            isClearable
                                            options={
                                                (ward && ward.length > 0 || ward.length === 0) &&
                                                ward.map((countyItem, i) => ({
                                                    label: countyItem.name,
                                                    value: countyItem.caw_code
                                                }))}
                                            placeholder="Select Wards"
                                            autosize={true}
                                            onChange={e => {
                                                setWardName(e.value)
                                                axios.get(`${baseURL_PORTAL}polling_m?id=${e.value}`, CONFIG).then(res => {
                                                    if (res.data.data.length > 0) {
                                                        setPollingStation(res.data.data);
                                                    }
                                                }).catch(err => {
                                                    console.log(err) //log error
                                                    setLoad(false);
                                                    // if error on the api
                                                })
                                            }
                                            }
                                            required
                                            className="selected"
                                            menuPortalTarget={document.body}
                                            name="namffe"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-10 offset-1">
                                <div className="form-group">
                                    <div className="col-md-12">
                                        <label className="form-label">Polling Stations</label>
                                    </div>
                                    <div className="col-md-12">
                                        <Select
                                            isClearable
                                            options={
                                                (polling_station && polling_station.length > 0 || polling_station.length === 0) &&
                                                polling_station.map((countyItem, i) => ({
                                                    label: countyItem.polling_station_name,
                                                    value: countyItem.p_code
                                                }))}
                                            placeholder="Select Polling Station"
                                            autosize={true}
                                            required
                                            onChange={e => setPName(e.value)}
                                            className="selected"
                                            menuPortalTarget={document.body}
                                            name="namffe"
                                        />
                                    </div>

                                </div>
                            </div>



                            <div className="col-md-10 offset-1">
                                <div className="form-group">
                                    <div className="col-md-12">
                                        <label className="form-label">Category</label>
                                    </div>
                                    <div className="col-md-12">
                                        <Select
                                            isClearable
                                            required
                                            options={
                                                (category && category.length > 0 || category.length === 0) &&
                                                category.map((countyItem, i) => ({
                                                    label: countyItem.cat_name,
                                                    value: countyItem.id
                                                }))}
                                            placeholder="Select Cateogory"
                                            autosize={true}
                                            onChange={e => setCategoryName(e.value)}
                                            className="selected"
                                            menuPortalTarget={document.body}
                                            name="namffe"
                                        />
                                    </div>

                                </div>
                            </div>

                            <div className="col-md-10 offset-1">
                                <div className="form-group">
                                    <div className="col-md-12">
                                        <label className="form-label">Voter Type</label>
                                    </div>
                                    <div className="col-md-12">
                                        <Select
                                            isClearable
                                            required
                                            options={
                                                (voter_type && voter_type.length > 0 || voter_type.length === 0) &&
                                                voter_type.map((countyItem, i) => ({
                                                    label: countyItem.voter_type,
                                                    value: countyItem.id
                                                }))}
                                            placeholder="Select Voter Type"
                                            autosize={true}
                                            onChange={e => setVoterTypeName(e.value)}
                                            className="selected"
                                            menuPortalTarget={document.body}
                                            name="namffe"
                                        />
                                    </div>

                                </div>
                            </div>


                            <div className="col-md-10 offset-1">
                                <div className="form-group">
                                    <div className="col-md-12">
                                        <label className="form-label">Sponder ID</label>
                                    </div>
                                    <div className="col-md-12">
                                        <input id="input" type="text" className="form-control"
                                            required
                                            name="sponser_id" placeholder='Sponser ID'
                                            value={sponser_id} onChange={handleChangeUsername} />
                                    </div>
                                </div>
                                <br />
                                <br />
                            </div>



                            <div className="account__btns col-8 offset-2" >
                                <Button className="account__btn" type='submit' color="success"> {
                                    isLoading ? "Please wait..." : "Submit"
                                } </Button>
                            </div>
                        </Form>
                    </>
                </Modal>

                <Modal
                    isOpen={open_add}
                    onRequestClose={e => {
                        closeModalAdd(e);
                    }}

                    contentLabel="My dialog"
                    className="mymodal"
                    onAfterOpen={() => {
                        document.body.style.overflow = 'hidden';
                    }}
                    onAfterClose={() => {
                        document.body.removeAttribute('style');
                    }}
                    overlayClassName="myoverlay"
                    closeTimeoutMS={500}
                >
                    <MDBCloseIcon onClick={closeModalAdd} />
                    <h4><b>Filter Form</b></h4>
                    <>
                        <Form className="form login-form" onSubmit={onSubmitHandleAdd} >


                            <div className="col-md-12">
                                <div className="form-group">
                                    <div className="col-md-12">
                                        <label className="form-label">Ward</label>
                                    </div>
                                    <div className="col-md-12">
                                        <Select
                                            isClearable
                                            options={
                                                (ward && ward.length > 0 || ward.length === 0) &&
                                                ward.map((countyItem, i) => ({
                                                    label: countyItem.name,
                                                    value: countyItem.caw_code
                                                }))}
                                            placeholder="Select Ward"
                                            autosize={true}
                                            onChange={e => {
                                                setWardName(e.value)
                                                setStage(1)
                                                axios.get(`${baseURL_PORTAL}polling_m?id=${e.value}`, CONFIG).then(res => {
                                                    if (res.data.data.length > 0) {
                                                        setPollingStation(res.data.data);
                                                    }
                                                }).catch(err => {
                                                    console.log(err) //log error
                                                    setLoad(false);
                                                    // if error on the api
                                                })
                                            }
                                            }

                                            className="selected"
                                            menuPortalTarget={document.body}
                                            name="namffe"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <div className="col-md-12">
                                        <label className="form-label">Polling Stations</label>
                                    </div>
                                    <div className="col-md-12">
                                        <Select
                                            isClearable
                                            options={
                                                (polling_station && polling_station.length > 0 || polling_station.length === 0) &&
                                                polling_station.map((countyItem, i) => ({
                                                    label: countyItem.polling_station_name,
                                                    value: countyItem.p_code
                                                }))}
                                            placeholder="Select Polling Station"
                                            autosize={true}

                                            onChange={e => {
                                                setPName(e.value)
                                                setStage(2)
                                            }}
                                            className="selected"
                                            menuPortalTarget={document.body}
                                            name="namffe"
                                        />
                                    </div>

                                </div>
                            </div>



                            <div className="col-md-12">
                                <div className="form-group">
                                    <div className="col-md-12">
                                        <label className="form-label">Category</label>
                                    </div>
                                    <div className="col-md-12">
                                        <Select
                                            isClearable

                                            options={
                                                (category && category.length > 0 || category.length === 0) &&
                                                category.map((countyItem, i) => ({
                                                    label: countyItem.cat_name,
                                                    value: countyItem.id
                                                }))}
                                            placeholder="Select Cateogory"
                                            autosize={true}
                                            onChange={e => {
                                                setCategoryName(e.value)
                                                setStage(3)
                                            }}
                                            className="selected"
                                            menuPortalTarget={document.body}
                                            name="namffe"
                                        />
                                    </div>

                                </div>
                            </div>

                            <div className="col-md-12">
                                <div className="form-group">
                                    <div className="col-md-12">
                                        <label className="form-label">Voter Type</label>
                                    </div>
                                    <div className="col-md-12">
                                        <Select
                                            isClearable

                                            options={
                                                (voter_type && voter_type.length > 0 || voter_type.length === 0) &&
                                                voter_type.map((countyItem, i) => ({
                                                    label: countyItem.voter_type,
                                                    value: countyItem.id
                                                }))}
                                            placeholder="Select Voter Type"
                                            autosize={true}
                                            onChange={e => {
                                                setVoterTypeName(e.value)
                                                setStage(4)

                                            }}
                                            className="selected"
                                            menuPortalTarget={document.body}
                                            name="namffe"
                                        />
                                    </div>

                                </div>
                                <br />
                            </div>


                            {/* <div className="col-md-10 offset-1">
                                <div className="form-group">
                                    <div className="col-md-12">
                                        <label className="form-label">Sponder ID</label>
                                    </div>
                                    <div className="col-md-12">
                                        <input id="input" type="text
                                                        " className="form-control"
                                            
                                            name="sponser_id" placeholder='Sponser ID'
                                            value={sponser_id} onChange={handleChangeUsername} />
                                    </div>
                                </div>
                                <br />
                                <br />
                            </div> */}



                            <div className="account__btns col-8 offset-2" >
                                <Button className="account__btn" type='submit' color="success"> {
                                    isLoading ? "Please wait..." : "Search"
                                } </Button>
                            </div>
                        </Form>
                    </>
                </Modal>
                < Card >
                    <CardBody >
                        <Table responsive hover>
                            <div className="panel-body" >
                                <div className="row">
                                    <div className="col-md-4">
                                        <h4> <b>Voters</b></h4>
                                    </div>

                                    <div className="col-2 float-right">
                                        <Button className="account__btn float-right" type='button' onClick={e => {
                                            IsOpenAdd(true)
                                            setVoterTypeName('')
                                            setCategoryName('')

                                            setWardName('')

                                            setPName('')
                                            setStage(0)
                                        }} color="primary">
                                            Filter Here
                                        </Button>
                                    </div>
                                    <div className="col-6">
                                        <button className="float-right" title="refresh" style={{ background: "white", border: "0px" }}
                                            onClick={
                                                () => {
                                                    setQueryString(require("randomstring").generate({
                                                        length: 1,
                                                        charset: 'alphanumeric',
                                                        capitalization: 'lowercase'
                                                    }))
                                                }} >
                                            Refresh <RefreshIcon />
                                        </button>
                                    </div>
                                </div>
                                <div >
                                    Total Voters: <b>{datatotal}</b>
                                    <br />
                                </div>
                                <ReactDatatable
                                    config={config}
                                    records={farmer}
                                    columns={columns}
                                    dynamic={true}
                                    id="tsc"
                                    loading={isLoading}
                                    total_record={datatotal}
                                    onChange={tableChangeHandler} />
                            </div>
                        </Table>
                    </CardBody>


                </Card>

            </>
        </div>
    )

}
export default Voters;
