import axios from 'axios';
import React, { Fragment, useEffect, useState } from 'react';
import { CardBody, Card, Button, Form } from 'reactstrap';
import { baseURL_PORTAL, CONFIG, errorToast, successToast, ToastTable, USER } from '../../configs/exports';
import ReactDatatable from '@ashvin27/react-datatable';
import moment from 'moment';
import { MDBCloseIcon } from "mdbreact";
import Modal from 'react-modal';
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import Select from "react-select";
import $ from 'jquery'
window.$ = window.jQuery = require('jquery')
require('../../_assets/jss/sms_counter.js')


const SendBlast = () => {
    // hooks definations variables srtores
    //const [value, setValue] = useState()
    const [message, setMessage] = useState("")

    const [isLoading, setLoad] = useState(false); // loading state

    const [randomstrng, setRandomString] = useState(''); // random string for the api


    const [sneder_id, setSenderID] = useState([]);


    const [sender_name, setSenderName] = useState('')
    const [api_key, setAPIKey] = useState('')
    const [access_key, setAccessKey] = useState('')
    const [client_id, setClientID] = useState('')


    const [county, setCounty] = useState([]);
    const [county_name, setCountyName] = useState(0)

    const [consituency, setConstituency] = useState([]);
    const [consituency_name, setConstituencyName] = useState(0)

    const [ward, setWard] = useState([]);
    const [ward_name, setWardName] = useState(0)


    const [polling_station, setPollingStation] = useState([]);
    const [p_name, setPName] = useState(0)

    const [title_name, setTitleName] = useState('')
    const [value_place, setValuePlace] = useState(0)

    const [title, setTitle] = useState([

        USER.title === 'governor' ?
            {
                label: "Send to the whole county",
                value_two: "1",
                value: "governor",
            } : {},

        USER.title === 'mp' || USER.title === 'governor' ?
            {
                label: "Send to a consituency",
                value_two: "2",
                value: "mp",
            }
            : {},
        {
            label: "send to ward ",
            value_two: "3",
            value: "mca",
        },
        // {
        //     label: "Specific polling stations",
        //     value_two: "4",
        //     value: "pol",
        // }
    ]);  // array of objects for data table  


    // function being called once a component has been loaded
    useEffect(() => {
        setLoad(true);

        axios.get(`${baseURL_PORTAL}counties`, CONFIG).then(res => {
            // console.log("Users", res.data.Users[1])
            if (res.data.data.length > 0) {
                setCounty(res.data.data);
            }
        }).catch(err => {
            console.log(err) //log error
        })


        axios.get(`${baseURL_PORTAL}sender_id_title?id=${USER.user_id}`, CONFIG).then(res => {
            // console.log("Users", res.data.Users[1])
            if (res.data.data.length > 0) {
                setSenderID(res.data.data);
            }
        }).catch(err => {
            console.log(err) //log error
        })
        setLoad(false)

        $('#message').countSms('#sms-counter');

        // if error on the api


    }, [randomstrng])



    // add user action point

    const onSubmitHandleAdd = (e) => {
        e.preventDefault();
        setLoad(true);

        let mtitle_id = 0;
        if (title_name === 'governor') {
            mtitle_id = county_name
        } else if (title_name === 'mp') {
            mtitle_id = consituency_name
        } else if (title_name === 'mca') {
            mtitle_id = ward_name
        }


        let formatData = {
            title: title_name,
            county_id: county_name,
            consituency_id: consituency_name,
            ward_id: ward_name,
            p_id: p_name,
            sender_id: sender_name,

            api_key: api_key,
            client_id: client_id,
            access_key: access_key,

            place: value_place,
            user_id: USER.user_id,
            message: message + "\nTO STOP *456*9*5#"
        }

        console.log(formatData)

        successToast("Success!");

        setTimeout(()=>{
            window.location.href = "/blast_logs"
        },2000)

        axios.post(`${baseURL_PORTAL}send_blast`, formatData, CONFIG).then(res => {
            setLoad(false);
            if (res.data.status) {
                successToast("Success!");
                // reload  after three sec0nd of success
                setTimeout(() => {
                    setRandomString(require("randomstring").generate({
                        length: 1,
                        charset: 'alphanumeric',
                        capitalization: 'lowercase'
                    }))
                }, 3000)
            } else {
                errorToast(res.data.message);
            }

        }).catch(err => {

            // console.log('error', err)
            errorToast("Error check your inputs");
        })



    }

    //return function of UI
    return (
        <div className='col-md-10 offset-1'>

            <Card>
                <CardBody>
                    {ToastTable()}

                    <h4><b>Send Blast</b></h4>
                    <br></br>
                    <>
                        <Form className="form login-form" onSubmit={onSubmitHandleAdd} >
                            <div className="col-md-10 offset-1">
                                <div className="form-group">
                                    <div className="col-md-12">
                                        <label className="form-label">Region</label>
                                    </div>
                                    <div className="col-md-12">
                                        <Select
                                            isClearable
                                            options={
                                                (title && title.length > 0 || title.length === 0) &&
                                                title.map((countyItem, i) => ({
                                                    label: countyItem.label,
                                                    value_two: countyItem.value_two,
                                                    value: countyItem.value
                                                }))}
                                            placeholder="Select Region"
                                            autosize={true}
                                            onChange={e => {
                                                setTitleName(e.value)
                                                setValuePlace(e.value_two)
                                            }}
                                            className="selected"
                                            menuPortalTarget={document.body}
                                            name="namffe"
                                        />
                                    </div>

                                </div>
                            </div>

                            <div className="col-md-10 offset-1">
                                <div className="form-group">
                                    <div className="col-md-12">
                                        <label className="form-label">SenderID</label>
                                    </div>
                                    <div className="col-md-12">
                                        <Select
                                            isClearable
                                            options={
                                                (sneder_id && sneder_id.length > 0 || sneder_id.length === 0) &&
                                                sneder_id.map((countyItem, i) => ({
                                                    label: countyItem.name,
                                                    api_key: countyItem.api_key,
                                                    client_id: countyItem.client_id,
                                                    access_key: countyItem.access_key,
                                                    value: countyItem.name
                                                }))}
                                            placeholder="Select Sender ID"
                                            autosize={true}
                                            onChange={e => {
                                                setSenderName(e.value)
                                                setAPIKey(e.api_key)
                                                setAccessKey(e.access_key)
                                                setClientID(e.client_id)

                                            }}
                                            className="selected"
                                            menuPortalTarget={document.body}
                                            name="namffe"
                                        />
                                    </div>

                                </div>
                            </div>

                            {title_name === 'governor' || title_name === 'mp' || title_name === 'mca' || title_name === 'pol' ?
                                <>
                                    <div className="col-md-10 offset-1">
                                        <div className="form-group">
                                            <div className="col-md-12">
                                                <label className="form-label">Counties</label>
                                            </div>
                                            <div className="col-md-12">
                                                <Select
                                                    isClearable
                                                    options={
                                                        (county && county.length > 0 || county.length === 0) &&
                                                        county.map((countyItem, i) => ({
                                                            label: countyItem.county_name,
                                                            value: countyItem.id
                                                        }))}
                                                    placeholder="Select County"
                                                    autosize={true}
                                                    onChange={e => {
                                                        setCountyName(e.value)
                                                        axios.get(`${baseURL_PORTAL}constituencies?id=${e.value}`, CONFIG).then(res => {
                                                            if (res.data.data.length > 0) {
                                                                setConstituency(res.data.data);
                                                            }
                                                        }).catch(err => {
                                                            console.log(err) //log error
                                                            setLoad(false);
                                                            // if error on the api
                                                        })
                                                    }}
                                                    className="selected"
                                                    menuPortalTarget={document.body}
                                                    name="namffe"
                                                />
                                            </div>

                                        </div>
                                    </div>

                                    {title_name === 'mp' || title_name === 'mca' || title_name === 'pol' ?
                                        <>
                                            <div className="col-md-10 offset-1">
                                                <div className="form-group">
                                                    <div className="col-md-12">
                                                        <label className="form-label">Constituencies</label>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <Select
                                                            isClearable
                                                            options={
                                                                (consituency && consituency.length > 0 || consituency.length === 0) &&
                                                                consituency.map((countyItem, i) => ({
                                                                    label: countyItem.name,
                                                                    value: countyItem.cosnt_code
                                                                }))}
                                                            placeholder="Select Consituency"
                                                            autosize={true}
                                                            onChange={e => {
                                                                setConstituencyName(e.value)
                                                                axios.get(`${baseURL_PORTAL}wards?id=${e.value}`, CONFIG).then(res => {
                                                                    if (res.data.data.length > 0) {
                                                                        setWard(res.data.data);
                                                                    }
                                                                }).catch(err => {
                                                                    console.log(err) //log error
                                                                    setLoad(false);
                                                                    // if error on the api
                                                                })


                                                            }}
                                                            className="selected"
                                                            menuPortalTarget={document.body}
                                                            name="namffe"
                                                        />
                                                    </div>

                                                </div>
                                            </div>

                                            {title_name === 'mca' || title_name === 'pol' ?
                                                <>
                                                    <div className="col-md-10 offset-1">
                                                        <div className="form-group">
                                                            <div className="col-md-12">
                                                                <label className="form-label">Ward</label>
                                                            </div>
                                                            <div className="col-md-12">
                                                                <Select
                                                                    isClearable
                                                                    options={
                                                                        (ward && ward.length > 0 || ward.length === 0) &&
                                                                        ward.map((countyItem, i) => ({
                                                                            label: countyItem.name,
                                                                            value: countyItem.caw_code
                                                                        }))}
                                                                    placeholder="Select Wards"
                                                                    autosize={true}
                                                                    onChange={e => {
                                                                        setWardName(e.value)
                                                                        axios.get(`${baseURL_PORTAL}polling?id=${e.value}`, CONFIG).then(res => {
                                                                            if (res.data.data.length > 0) {
                                                                                setPollingStation(res.data.data);
                                                                            }
                                                                        }).catch(err => {
                                                                            console.log(err) //log error
                                                                            setLoad(false);
                                                                            // if error on the api
                                                                        })
                                                                    }
                                                                    }
                                                                    className="selected"
                                                                    menuPortalTarget={document.body}
                                                                    name="namffe"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                                : null}

                                            {title_name === 'pol' ?


                                                <div className="col-md-10 offset-1">
                                                    <div className="form-group">
                                                        <div className="col-md-12">
                                                            <label className="form-label">Polling Stations</label>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <Select
                                                                isClearable
                                                                options={
                                                                    (polling_station && polling_station.length > 0 || polling_station.length === 0) &&
                                                                    polling_station.map((countyItem, i) => ({
                                                                        label: countyItem.western_polling_station,
                                                                        value: countyItem.pstation_code
                                                                    }))}
                                                                placeholder="Select Polling Station"
                                                                autosize={true}
                                                                onChange={e => setPName(e.value)}
                                                                className="selected"
                                                                menuPortalTarget={document.body}
                                                                name="namffe"
                                                            />
                                                        </div>

                                                    </div>
                                                </div>
                                                : null}
                                        </>
                                        : null}
                                </>
                                : null}
                            <div className="col-md-10 offset-1 form-group">
                                <br />
                                <br />
                                <div className="row">

                                    <div className="col-md-8" >
                                        <textarea name="message" style={{ paddingTop: '20px' }} onChange={e => setMessage(e.target.value)}
                                            className="col-md-12" placeholder="type your message here"
                                            required
                                            id="message" cols="col-md-1" rows="20"></textarea>
                                    </div>
                                    <div className="col-md-4">
                                        <ul id="sms-counter">
                                            <li>Length: <span class="length"></span></li>
                                            <li>SMS: <span class="messages"></span></li>
                                            <li>Per Message: <span class="per_message"></span></li>
                                            <li>Remaining: <span class="remaining"></span></li>
                                        </ul>
                                    </div>
                                </div>
                            </div><br />


                            <div className="col-6 offset-3 float-right" >
                                <br />
                                <br />
                                <Button className="account__btn" type='submit' color="success"> {
                                    isLoading ? "Please wait..." : "Submit"
                                } </Button>
                            </div>
                        </Form>
                    </>
                </CardBody>
            </Card>
        </div >
    )
}

export default SendBlast;