import axios from 'axios';
import React, { Fragment, useEffect, useState } from 'react';
import { CardBody, Card, Button, Form } from 'reactstrap';
import { baseURL_PORTAL, CONFIG, errorToast, successToast, ToastTable } from '../../configs/exports';
import ReactDatatable from '@ashvin27/react-datatable';
import moment from 'moment';
import { MDBCloseIcon } from "mdbreact";
import Modal from 'react-modal';
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import Select from "react-select";

const UsersStaffs = () => {

    // hooks definations variables srtores
    //const [value, setValue] = useState()
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [name, setName] = useState("")
    const [msisdn, setMsisdn] = useState("")
    const [open_add, IsOpenAdd] = useState(false);  // define open edit modal

    const [data, setData] = useState([]);  // array of objects for data table  
    const [isLoading, setLoad] = useState(false); // loading state
    const [randomstrng, setRandomString] = useState(''); // random string for the api


    const [title, setTitle] = useState([
        {
            label: "Governor",
            value: "governor",
        },
        {
            label: "MP",
            value: "mp",
        },
        {
            label: "MCA",
            value: "mca",
        }
    ]);  // array of objects for data table  

    const [title_name, setTitleName] = useState('admin')


    const [county, setCounty] = useState([]);
    const [county_name, setCountyName] = useState('')

    const [consituency, setConstituency] = useState([]);
    const [consituency_name, setConstituencyName] = useState('')

    const [ward, setWard] = useState([]);
    const [ward_name, setWardName] = useState('')


    const [polling_station, setPollingStation] = useState([]);
    const [p_name, setPName] = useState('')

    // function being called once a component has been loaded
    useEffect(() => {
        setLoad(true);


        axios.get(`${baseURL_PORTAL}user`, CONFIG).then(res => {
            let new_data = []; // difine a new object
            // console.log("Users", res.data.Users[1])

            if (res.data.data.length > 0) {
                // looping  through the array for modifing the data
                for (let i = 0; i < res.data.data.length; i++) {
                    let new_date = { new_date: moment(res.data.data[i].created_on).format('DD-MM-YYYY') }
                    new_data.push(Object.assign(new_date, res.data.data[i]));
                }
                setLoad(false);
            }
            setData(new_data);
        }).catch(err => {
            console.log(err) //log error
            setLoad(false);
            // if error on the api
        })


        axios.get(`${baseURL_PORTAL}counties`, CONFIG).then(res => {
            // console.log("Users", res.data.Users[1])
            if (res.data.data.length > 0) {
                setCounty(res.data.data);
            }
        }).catch(err => {
            console.log(err) //log error
            setLoad(false);
            // if error on the api
        })



    }, [randomstrng])





    // difine the table format
    const columns = [
        {
            key: "name",
            text: "Name",
            className: "tsc",
            TrOnlyClassName: 'tsc',
            sortable: true
        },
        {
            key: "username",
            text: "Username",
            className: "tsc",
            TrOnlyClassName: 'tsc',
            sortable: true
        },
        {
            key: "msisdn",
            text: "Phone Number",
            className: "tsc",
            TrOnlyClassName: 'tsc',
            sortable: true
        },
        {
            key: "title",
            text: "Title",
            TrOnlyClassName: 'tsc',
            className: "tsc",

            sortable: true
        },
        {
            key: "new_date",
            text: "Date Created",
            TrOnlyClassName: 'tsc',
            className: "tsc",

            sortable: true
        },
        {
            key: "action",
            text: "Action",
            TrOnlyClassName: 'tsc',
            cell: (record, index) => {
                return (
                    <Fragment>
                        <button
                            className="btn btn-danger btn-sm"
                            onClick={() => { if (window.confirm('Are you sure you want to delete this user?')) handleDelete(record) }}
                        >
                            Delete
                        </button>
                    </Fragment>
                );
            }
        }
    ]

    // table configs
    const config = {
        page_size: 100,
        length_menu: [100, 200, 500],
        show_filter: true,
        show_pagination: true,
        filename: "users",
        button: {
            excel: true,
            print: true,
            csv: true
        }
    }

    //  close add modal
    const closeModalAdd = () => {
        IsOpenAdd(false)
    }

    const handleChangeUsername = (e) => {
        setUsername(e.target.value)
    }
    const handleChangePassword = (e) => {
        setPassword(e.target.value)
    }
    const handleChangeNanme = (e) => {
        setName(e.target.value)
    }
    // agent specific option


    // handle delete action
    const handleDelete = (e) => {
        setLoad(true);
        let formatData = {
            id: e.id,

        }

        axios.post(`${baseURL_PORTAL}delete_user`, formatData, CONFIG).then(res => {
            // (`${baseURL_PORTAL}user
            setLoad(false);
            if (res.data.success) {
                successToast("User deleted successfully");
                // reload  after three sec0nd of success
                setTimeout(() => {
                    setRandomString(require("randomstring").generate({
                        length: 1,
                        charset: 'alphanumeric',
                        capitalization: 'lowercase'
                    }))
                }, 3000)
            }

        }).catch(
            err => {
                errorToast("User not deleted")
            }
        )
    }

    // add branch action point
    // add user action point

    const onSubmitHandleAdd = (e) => {
        e.preventDefault();
        setLoad(true);

        let mtitle_id = 0;
        if (title_name === 'governor') {
            mtitle_id = county_name
        } else if (title_name === 'mp') {
            mtitle_id = consituency_name
        } else if (title_name === 'mca') {
            mtitle_id = ward_name
        }

        let formatData = {
            name: name,
            username: username,
            msisdn: msisdn,
            title: title_name,
            title_id: mtitle_id,
            password: password
        }


        axios.post(`${baseURL_PORTAL}user`, formatData, CONFIG).then(res => {
            setLoad(false);
            if (res.data.success) {
                successToast("User added successfully");
                // reload  after three sec0nd of success
                setTimeout(() => {
                    setRandomString(require("randomstring").generate({
                        length: 1,
                        charset: 'alphanumeric',
                        capitalization: 'lowercase'
                    }))
                    IsOpenAdd(false);
                }, 3000)
            }

        }).catch(err => {
            errorToast(err.response.data.message);
        })



    }

    //return function of UI
    return (
        <div>

            {ToastTable()}

            <Modal
                isOpen={open_add}
                onRequestClose={e => {
                    closeModalAdd(e);
                }}

                contentLabel="My dialog"
                className="mymodal"
                onAfterOpen={() => {
                    document.body.style.overflow = 'hidden';
                }}
                onAfterClose={() => {
                    document.body.removeAttribute('style');
                }}
                overlayClassName="myoverlay"
                closeTimeoutMS={500}
            >
                <MDBCloseIcon onClick={closeModalAdd} />
                <h4><b>Add User</b></h4>
                <br></br>
                <>
                    <Form className="form login-form" onSubmit={onSubmitHandleAdd} >

                        <div className="col-md-10 offset-1"><br />
                            <div className="form-group">
                                <div className="col-md-12">
                                    <label className="form-label">Name</label>
                                </div>
                                <div className="col-md-12">
                                    <input id="input" type="text
                                                        "
                                        className="form-control"
                                        name="name" placeholder='Name'
                                        value={name} onChange={handleChangeNanme} />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-10 offset-1">
                            <div className="form-group">
                                <div className="col-md-12">
                                    <label className="form-label">Title</label>
                                </div>
                                <div className="col-md-12">
                                    <Select
                                        isClearable
                                        options={
                                            (title && title.length > 0 || title.length === 0) &&
                                            title.map((countyItem, i) => ({
                                                label: countyItem.label,
                                                value: countyItem.value
                                            }))}
                                        placeholder="Select Counties"
                                        autosize={true}
                                        onChange={e => {
                                            setTitleName(e.value)
                                        }}
                                        className="selected"
                                        menuPortalTarget={document.body}
                                        name="namffe"
                                    />
                                </div>

                            </div>
                        </div>
                        {title_name === 'governor' || title_name === 'mp' || title_name === 'mca' ?
                            <>
                                <div className="col-md-10 offset-1">
                                    <div className="form-group">
                                        <div className="col-md-12">
                                            <label className="form-label">Counties</label>
                                        </div>
                                        <div className="col-md-12">
                                            <Select
                                                isClearable
                                                options={
                                                    (county && county.length > 0 || county.length === 0) &&
                                                    county.map((countyItem, i) => ({
                                                        label: countyItem.county_name,
                                                        value: countyItem.id
                                                    }))}
                                                placeholder="Select Counties"
                                                autosize={true}
                                                onChange={e => {
                                                    setCountyName(e.value)
                                                    axios.get(`${baseURL_PORTAL}constituencies?id=${e.value}`, CONFIG).then(res => {
                                                        if (res.data.data.length > 0) {
                                                            setConstituency(res.data.data);
                                                        }
                                                    }).catch(err => {
                                                        console.log(err) //log error
                                                        setLoad(false);
                                                        // if error on the api
                                                    })
                                                }}
                                                className="selected"
                                                menuPortalTarget={document.body}
                                                name="namffe"
                                            />
                                        </div>

                                    </div>
                                </div>

                                {title_name === 'mp' || title_name === 'mca' ?
                                    <>
                                        <div className="col-md-10 offset-1">
                                            <div className="form-group">
                                                <div className="col-md-12">
                                                    <label className="form-label">Constituencies</label>
                                                </div>
                                                <div className="col-md-12">
                                                    <Select
                                                        isClearable
                                                        options={
                                                            (consituency && consituency.length > 0 || consituency.length === 0) &&
                                                            consituency.map((countyItem, i) => ({
                                                                label: countyItem.name,
                                                                value: countyItem.cosnt_code
                                                            }))}
                                                        placeholder="Select Consituency"
                                                        autosize={true}
                                                        onChange={e => {
                                                            setConstituencyName(e.value)
                                                            axios.get(`${baseURL_PORTAL}wards?id=${e.value}`, CONFIG).then(res => {
                                                                if (res.data.data.length > 0) {
                                                                    setWard(res.data.data);
                                                                }
                                                            }).catch(err => {
                                                                console.log(err) //log error
                                                                setLoad(false);
                                                                // if error on the api
                                                            })


                                                        }}
                                                        className="selected"
                                                        menuPortalTarget={document.body}
                                                        name="namffe"
                                                    />
                                                </div>

                                            </div>
                                        </div>

                                        {title_name === 'mca' ?
                                            <div className="col-md-10 offset-1">
                                                <div className="form-group">
                                                    <div className="col-md-12">
                                                        <label className="form-label">Ward</label>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <Select
                                                            isClearable
                                                            options={
                                                                (ward && ward.length > 0 || ward.length === 0) &&
                                                                ward.map((countyItem, i) => ({
                                                                    label: countyItem.name,
                                                                    value: countyItem.caw_code
                                                                }))}
                                                            placeholder="Select Wards"
                                                            autosize={true}
                                                            onChange={e => {
                                                                setWardName(e.value)
                                                                axios.get(`${baseURL_PORTAL}polling?id=${e.value}`, CONFIG).then(res => {
                                                                    if (res.data.data.length > 0) {
                                                                        setPollingStation(res.data.data);
                                                                    }
                                                                }).catch(err => {
                                                                    console.log(err) //log error
                                                                    setLoad(false);
                                                                    // if error on the api
                                                                })
                                                            }
                                                            }
                                                            className="selected"
                                                            menuPortalTarget={document.body}
                                                            name="namffe"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            : null}
                                    </>
                                    : null}
                            </>
                            : null}



                        <div className="col-md-10 offset-1">
                            <div className="form-group">
                                <div className="col-md-12">
                                    <label className="form-label">Username</label>
                                </div>
                                <div className="col-md-12">
                                    <input id="input" type="text
                                                        " className="form-control"
                                        name="username" placeholder='Username'
                                        value={username} onChange={handleChangeUsername} />
                                </div>
                            </div>
                        </div>


                        <div className="col-md-10 offset-1">
                            <div className="form-group">
                                <div className="col-md-12">
                                    <label className="form-label">Phone Number</label>
                                </div>
                                <div className="col-md-12">
                                    <PhoneInput
                                        className="form-group"
                                        defaultCountry="KE"
                                        placeholder="Enter phone number"
                                        value={msisdn}
                                        id="input"
                                        onChange={value => setMsisdn(value)}
                                    />
                                </div>
                            </div>
                        </div>





                        <div className="col-md-10 offset-1">
                            <div className="form-group">
                                <div className="col-md-12">
                                    <label className="form-label">Password</label>
                                </div>
                                <div className="col-md-12">
                                    <input id="input" type="email
                                                        " className="form-control"
                                        name="password" placeholder='Enter password'
                                        value={password} onChange={handleChangePassword} />
                                </div>
                            </div>
                            <br />
                            <br />

                        </div>


                        <div className="account__btns col-8 offset-2" >
                            <Button className="account__btn" type='submit' color="success"> {
                                isLoading ? "Please wait..." : "Submit"
                            } </Button>
                        </div>
                    </Form>
                </>
            </Modal>

            <Card>
                <CardBody>
                    <div className='row'>
                        <div className='col-10'>
                            <h4><b>Users</b></h4>
                        </div>
                        <div className='col-2 float-right'>
                            <div className='3'>
                                <Button className="account__btn" color="success" onClick={() => { IsOpenAdd(true) }}>Add User</Button>
                                <br />

                            </div>
                            <br />
                            <br />
                        </div>
                        <br />
                    </div>
                    <ReactDatatable
                        config={config}
                        records={data}
                        loading={isLoading}
                        columns={columns}
                        id="tsc"
                    />
                </CardBody>

            </Card>
        </div >
    )
}

export default UsersStaffs;