import axios from 'axios';
import React, { Fragment, useEffect, useState } from 'react';
import { CardBody, Card, Button, Form } from 'reactstrap';
import { baseURL_PORTAL, CONFIG, errorToast, successToast, ToastTable } from '../../configs/exports';
import ReactDatatable from '@ashvin27/react-datatable';
import moment from 'moment';
import { MDBCloseIcon } from "mdbreact";
import Modal from 'react-modal';
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import Select from "react-select";

const SouthMigarango = () => {

    // hooks definations variables srtores
    //const [value, setValue] = useState()
    const [sponser_id, setSponserID] = useState("")
    const [password, setPassword] = useState("")

    const [id_number, setIDNumber] = useState("")

    const [name, setName] = useState("")
    const [msisdn, setMsisdn] = useState("")
    const [open_add, IsOpenAdd] = useState(false);  // define open edit modal

    const [data, setData] = useState([]);  // array of objects for data table  
    const [isLoading, setLoad] = useState(false); // loading state
    const [randomstrng, setRandomString] = useState(''); // random string for the api


    const [title, setTitle] = useState([
        {
            label: "Governor",
            value: "governor",
        },
        {
            label: "MP",
            value: "mp",
        },
        {
            label: "MCA",
            value: "mca",
        }
    ]);  // array of objects for data table  

    const [title_name, setTitleName] = useState('admin')


    const [county, setCounty] = useState([]);
    const [county_name, setCountyName] = useState('')

    const [consituency, setConstituency] = useState([]);
    const [consituency_name, setConstituencyName] = useState('')

    const [ward, setWard] = useState([]);
    const [ward_name, setWardName] = useState('')


    const [polling_station, setPollingStation] = useState([]);
    const [p_name, setPName] = useState('')



    const [category, setCategory] = useState([]);
    const [category_name, setCategoryName] = useState('')


    const [voter_type, setVoterType] = useState([]);
    const [voter_type_name, setVoterTypeName] = useState('')

    // function being called once a component has been loaded
    useEffect(() => {
        setLoad(false);

        axios.get(`${baseURL_PORTAL}wards_m?id=262`, CONFIG).then(res => {
            if (res.data.data.length > 0) {
                setWard(res.data.data);
            }
        }).catch(err => {
            console.log(err) //log error
            setLoad(false);
            // if error on the api
        })

        axios.get(`${baseURL_PORTAL}m_category`, CONFIG).then(res => {
            if (res.data.data.length > 0) {
                setCategory(res.data.data);
            }
        }).catch(err => {
            console.log(err) //log error
            setLoad(false);
            // if error on the api
        })

        axios.get(`${baseURL_PORTAL}m_voter`, CONFIG).then(res => {
            if (res.data.data.length > 0) {
                setVoterType(res.data.data);
            }
        }).catch(err => {
            console.log(err) //log error
            setLoad(false);
            // if error on the api
        })

    }, [randomstrng])




    //  close add modal
    const closeModalAdd = () => {
        IsOpenAdd(false)
    }

    const handleChangeUsername = (e) => {
        setSponserID(e.target.value)
    }
    const handleChangePassword = (e) => {
        setPassword(e.target.value)
    }
    const handleChangeNanme = (e) => {
        setName(e.target.value)
    }
    // agent specific option

    // add branch action point
    // add user action point

    const onSubmitHandleAdd = (e) => {
        e.preventDefault();
        setLoad(true);


        let formatData = {
            name: name,
            msisdn: msisdn,
            ward_id: ward_name,
            constituency_id: consituency_name,
            county_id: county_name,
            polling_station_id: p_name,
            category_id: category_name,
            voter_type_id: voter_type_name,
            sponsor_id: sponser_id,
            id_number: id_number
        }


        // console.log("ID NUMBER", formatData)


        axios.post(`${baseURL_PORTAL}create_voter`, formatData, CONFIG).then(res => {
            setLoad(false);
            if (res.data.status) {
                successToast("Added successfully");


                setName("")
                setMsisdn("")
                setWardName("")
                setConstituencyName("")
                setCountyName("")
                setPName("")
                setCategoryName("")
                setVoterTypeName("")
                setSponserID("")
                setIDNumber("")
                
                // window.location.reload()
                // reload  after three sec0nd of success
                setTimeout(() => {
                    // window.location.reload()
                    // IsOpenAdd(false);
                }, 1000)
            }

        }).catch(err => {
            setLoad(false);
            errorToast(err.response.data.message);
        })



    }

    //return function of UI
    return (
        <div>

            {ToastTable()}

            <Modal
                isOpen={true}
                onRequestClose={e => {
                    closeModalAdd(e);
                }}

                contentLabel="My dialog"
                className="mymodal"
                onAfterOpen={() => {
                    document.body.style.overflow = 'hidden';
                }}
                onAfterClose={() => {
                    document.body.removeAttribute('style');
                }}
                overlayClassName="myoverlay"
                closeTimeoutMS={500}
            >
                <MDBCloseIcon onClick={closeModalAdd} />
                <br /><br /><br />
                <h4><b>Add Voter</b></h4>
                <br></br>
                <>
                    <Form className="form login-form" onSubmit={onSubmitHandleAdd} >

                        <div className="col-md-10 offset-1"><br />
                            <div className="form-group">
                                <div className="col-md-12">
                                    <label className="form-label">Name</label>
                                </div>
                                <div className="col-md-12">
                                    <input id="input" type="text
                                                        "
                                        className="form-control"
                                        name="name" placeholder='Name'
                                        required
                                        value={name} onChange={handleChangeNanme} />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-10 offset-1">
                            <div className="form-group">
                                <div className="col-md-12">
                                    <label className="form-label">ID No</label>
                                </div>
                                <div className="col-md-12">
                                    <input id="input" type="text"
                                        className="form-control"
                                        required
                                        name="id_number" placeholder='ID Number'
                                        value={id_number} onChange={e => setIDNumber(e.target.value)} />
                                </div>
                            </div>
                        </div>



                        <div className="col-md-10 offset-1">
                            <div className="form-group">
                                <div className="col-md-12">
                                    <label className="form-label">Phone Number</label>
                                </div>
                                <div className="col-md-12">
                                    <PhoneInput
                                        className="form-group"
                                        defaultCountry="KE"
                                        placeholder="Enter phone number"
                                        value={msisdn}
                                        required
                                        id="input"
                                        onChange={value => setMsisdn(value)}
                                    />
                                </div>
                            </div>
                        </div>



                        <div className="col-md-10 offset-1">
                            <div className="form-group">
                                <div className="col-md-12">
                                    <label className="form-label">Ward</label>
                                </div>
                                <div className="col-md-12">
                                    <Select
                                        isClearable
                                        options={
                                            (ward && ward.length > 0 || ward.length === 0) &&
                                            ward.map((countyItem, i) => ({
                                                label: countyItem.name,
                                                value: countyItem.caw_code
                                            }))}
                                        placeholder="Select Wards"
                                        autosize={true}
                                        onChange={e => {
                                            setWardName(e.value)
                                            axios.get(`${baseURL_PORTAL}polling_m?id=${e.value}`, CONFIG).then(res => {
                                                if (res.data.data.length > 0) {
                                                    setPollingStation(res.data.data);
                                                }
                                            }).catch(err => {
                                                console.log(err) //log error
                                                setLoad(false);
                                                // if error on the api
                                            })
                                        }
                                        }
                                        required
                                        className="selected"
                                        menuPortalTarget={document.body}
                                        name="namffe"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-10 offset-1">
                            <div className="form-group">
                                <div className="col-md-12">
                                    <label className="form-label">Polling Stations</label>
                                </div>
                                <div className="col-md-12">
                                    <Select
                                        isClearable
                                        options={
                                            (polling_station && polling_station.length > 0 || polling_station.length === 0) &&
                                            polling_station.map((countyItem, i) => ({
                                                label: countyItem.polling_station_name,
                                                value: countyItem.p_code
                                            }))}
                                        placeholder="Select Polling Station"
                                        autosize={true}
                                        required
                                        onChange={e => setPName(e.value)}
                                        className="selected"
                                        menuPortalTarget={document.body}
                                        name="namffe"
                                    />
                                </div>

                            </div>
                        </div>



                        <div className="col-md-10 offset-1">
                            <div className="form-group">
                                <div className="col-md-12">
                                    <label className="form-label">Category</label>
                                </div>
                                <div className="col-md-12">
                                    <Select
                                        isClearable
                                        required
                                        options={
                                            (category && category.length > 0 || category.length === 0) &&
                                            category.map((countyItem, i) => ({
                                                label: countyItem.cat_name,
                                                value: countyItem.id
                                            }))}
                                        placeholder="Select Cateogory"
                                        autosize={true}
                                        onChange={e => setCategoryName(e.value)}
                                        className="selected"
                                        menuPortalTarget={document.body}
                                        name="namffe"
                                    />
                                </div>

                            </div>
                        </div>

                        <div className="col-md-10 offset-1">
                            <div className="form-group">
                                <div className="col-md-12">
                                    <label className="form-label">Voter Type</label>
                                </div>
                                <div className="col-md-12">
                                    <Select
                                        isClearable
                                        required
                                        options={
                                            (voter_type && voter_type.length > 0 || voter_type.length === 0) &&
                                            voter_type.map((countyItem, i) => ({
                                                label: countyItem.voter_type,
                                                value: countyItem.id
                                            }))}
                                        placeholder="Select Voter Type"
                                        autosize={true}
                                        onChange={e => setVoterTypeName(e.value)}
                                        className="selected"
                                        menuPortalTarget={document.body}
                                        name="namffe"
                                    />
                                </div>

                            </div>
                        </div>


                        <div className="col-md-10 offset-1">
                            <div className="form-group">
                                <div className="col-md-12">
                                    <label className="form-label">Sponder ID</label>
                                </div>
                                <div className="col-md-12">
                                    <input id="input" type="text
                                                        " className="form-control"
                                                        required
                                        name="sponser_id" placeholder='Sponser ID'
                                        value={sponser_id} onChange={handleChangeUsername} />
                                </div>
                            </div>
                            <br />
                            <br />
                        </div>



                        <div className="account__btns col-8 offset-2" >
                            <Button className="account__btn" type='submit' color="success"> {
                                isLoading ? "Please wait..." : "Submit"
                            } </Button>
                        </div>
                    </Form>
                </>
            </Modal>

            <Card>
                <CardBody>

                </CardBody>

            </Card>
        </div >
    )
}

export default SouthMigarango;