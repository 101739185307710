import React from 'react';
import PropTypes from 'prop-types';
import SidebarLink from './SidebarLink';
import SidebarCategory from './SidebarCategory';
import { USER } from '../../../configs/exports';
// import { USER } from '../../../configs/exports';

const SidebarContent = ({
  onClick, changeToLight, changeToDark, sidebarCollapse,
}) => {
  const hideSidebar = () => {
    onClick();

  };

  return (
    <div className="sidebar__content">


      {USER.username === 'admin_admin' ?
        <ul className="sidebar__block">

          <SidebarLink
            title="ADD VOTERS"
            icon="list"
            route="/south_migarango_form"
            onClick={hideSidebar}
          />

          <SidebarLink
            title="VOTERS"
            icon="users"
            route="/voters"
            onClick={hideSidebar}
          />


        </ul>

        :
        <div>
          <ul className="sidebar__block">

            <SidebarLink
              title="SEND MESSAGE"
              icon="bullhorn"
              route="/send_blast"
              onClick={hideSidebar}
            />

            <SidebarLink
              title="MESSAGES"
              icon="phone"
              route="/blast_logs"
              onClick={hideSidebar}
            />

            <SidebarLink
              title="ADD CONTACT"
              icon="phone"
              route="/add_contact"
              onClick={hideSidebar}
            />
          </ul>
          {USER.title === 'admin' ?
            <ul className="sidebar__block">
              <SidebarCategory title="Settings" icon="cog" sidebarCollapse={sidebarCollapse}>


                <SidebarLink
                  title="SenderIDs"
                  route="/shortcodes"
                  onClick={hideSidebar}
                />

                <SidebarLink
                  title="Users"
                  route="/staffs"
                  onClick={hideSidebar}
                />

              </SidebarCategory>

            </ul>
            : null}



        </div>
      }
      <ul className="sidebar__block">
        <SidebarLink title="Log Out" icon="exit" route="/log_in" />
      </ul>

    </div>

  );
};

SidebarContent.propTypes = {
  changeToDark: PropTypes.func.isRequired,
  changeToLight: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  sidebarCollapse: PropTypes.bool,
};

SidebarContent.defaultProps = {
  sidebarCollapse: false,
};

export default SidebarContent;
